import React from "react";
import CustomButton, { BackButton, ButtonPosition } from "../../components/buttons";
import FoodImg from "../../assets/images/png/food-2.png";
import AddOnImg1 from "../../assets/images/png/add-on-1.png";
import AddOnImg2 from "../../assets/images/png/add-on-2.png";
import { useNavigate } from "react-router-dom";

export default function FoodDetails() {
    const navigate = useNavigate();
  return (
    <div className="container">
      <BackButton />
      <div className="absolute top-0 left-0 z-0">
        <img
          src={FoodImg}
          alt="Food"
          className="w-full"
          style={{ maxHeight: "340px" }}
        />
        <div className="p-3">
          <div className="flex justify-content-between align-items-center">
            <h3>Cheesy Chicken Burger</h3>
            <div
              className="p-2 border-1 border-round-lg"
              style={{ borderColor: "#FF4848" }}
            >
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  display: "block",
                  background: "#FF4848",
                }}
              ></span>
            </div>
          </div>
          <p className="text-lg font-bold">Description</p>
          <p>
            A classic favorite, our chicken burger features a juicy, grilled or
            breaded chicken patty served on a soft bun, accompanied by crisp
            lettuce, ripe tomatoes, sliced onions, and your choice of
            condiments.
          </p>
          <p className="text-lg font-bold">Ingridents</p>
          <p>
            20g Lorem ipsum, 8g Dolor sit, 12g Amet, 5g Consectetur, 4g
            Adipiscing
          </p>
          <p className="text-lg font-bold">Choice of Add On</p>
          <div className="flex justify-content-between align-items-center">
            <div className="flex justify-content-between align-items-center gap-3">
              <img src={AddOnImg1} alt="Add-on" />
              <p>Pepper Julienned</p>
            </div>
            <div className="flex justify-content-between align-items-center gap-3">
              <p>+ ₹50</p>
              <div
                className="p-1 border-1"
                style={{ borderColor: "#ccc", borderRadius: "50%" }}
              >
                <span
                  style={{
                    width: "13px",
                    height: "13px",
                    borderRadius: "50%",
                    display: "block",
                    background: "#EBC846",
                  }}
                ></span>
              </div>
            </div>
          </div>
          <div className="flex justify-content-between align-items-center">
            <div className="flex justify-content-between align-items-center gap-3">
              <img src={AddOnImg2} alt="Add-on" />
              <p>Baby Spinach</p>
            </div>
            <div className="flex justify-content-between align-items-center gap-3">
              <p>+ ₹50</p>
              <div
                className="p-1 border-1"
                style={{ borderColor: "#ccc", borderRadius: "50%" }}
              >
                <span
                  style={{
                    width: "13px",
                    height: "13px",
                    borderRadius: "50%",
                    display: "block",
                    // background: "#EBC846",
                  }}
                ></span>
              </div>
            </div>
          </div>
          <div className="flex justify-content-between align-items-center">
            <div className="flex justify-content-between align-items-center gap-3">
              <img src={AddOnImg1} alt="Add-on" />
              <p>Pepper Julienned</p>
            </div>
            <div className="flex justify-content-between align-items-center gap-3">
              <p>+ ₹50</p>
              <div
                className="p-1 border-1"
                style={{ borderColor: "#ccc", borderRadius: "50%" }}
              >
                <span
                  style={{
                    width: "13px",
                    height: "13px",
                    borderRadius: "50%",
                    display: "block",
                    // background: "#EBC846",
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonPosition>
        <CustomButton label="Add items - ₹ 300 " onClick={()=> navigate("/cart")}/>
      </ButtonPosition>
    </div>
  );
}
