import React from "react";
import CustomButton, { BackButton, ButtonPosition } from "../../components/buttons";
import Facility1 from "../../assets/images/png/facility-details.png";
import { useNavigate } from "react-router-dom";

export default function FacilityDetails() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <BackButton />
      <div className="absolute top-0 left-0 z-0">
        <img src={Facility1} alt="Facility" className="w-full" style={{maxHeight:"340px"}}/>
        <div className="p-3">
          <h3>Latitude</h3>
          <p className="text-lg">Description</p>
          <p className="text-xs">
            Savour the vibrant flavours and crisp textures of our meticulously
            authenticity crafted Buffet at Latitude. From crisp greens to
            seasonal vegetables, each bite promises a burst of freshness.
            Elevat...
          </p>
          <p className="text-lg">Cuisine</p>
          <p className="text-xs">All Day Dining, Multi Cuisine</p>
          <p className="text-lg">Cuisine</p>
          <p className="text-xs">
            24*7 (except on Tuesday it will be closed between 12:00 AM to 06:00
            AM)
          </p>
        </div>
      </div>
      <ButtonPosition><CustomButton label="Check Availability" onClick={()=> navigate("/facility-check-availability")}/></ButtonPosition>
    </div>
  );
}
