import React from 'react'
import "./App.scss"
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './routes'
import { PrivateRoutes } from './routes/routes'
import OnBoarding from './pages/OnBoarding'
import Home from './views/Home'
import Login from './pages/Login'
import Verification from "./pages/Verification"
import Reception from './views/Reception'
import Chat from './views/Chat'
import Lights from './views/Lights'
import AirConditioner from './views/AirConditioner'
import FoodOrder from './views/Food/FoodOrder'
import Services from './views/Services/Services'
import ReviewRequest from './views/Services/ReviewRequest'
import PendingRequests from './views/Services/PendingRequests'
import Facilities from './views/Faciliies/Facilities'
import FacilityDetails from './views/Faciliies/FacilityDetails'
import FacilityAvailability from './views/Faciliies/FacilityAvailability'
import SpecialOffer from './views/Food/SpecialOffer'
import FoodDetails from './views/Food/FoodDetails'
import Cart from './views/Food/Cart'
export default function App() {
  return (
    <HashRouter>
      <Routes>
      <Route path="/" element={<OnBoarding />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp-verification" element={<Verification />} />
      <Route path="/home" element={<Home />} />
      <Route path="/reception" element={<Reception />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="/facility-details" element={<FacilityDetails />} />
      <Route path="/facility-check-availability" element={<FacilityAvailability />} />
      <Route path="/services" element={<Services />} />
      <Route path="/review-request" element={<ReviewRequest />} />
      <Route path="/pending-requests" element={<PendingRequests />} />
      <Route path="/light-control" element={<Lights />} />
      <Route path="/ac-control" element={<AirConditioner />} />
      <Route path="/food-order" element={<FoodOrder />} />
      <Route path="/special-offer" element={<SpecialOffer />} />
      <Route path="/food-details" element={<FoodDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route element={<ProtectedRoute />}>
                            {PrivateRoutes?.map((route, key) => (
                                <Route key={key} path={route.path} element={route.element} exact={route.exact}>
                                    {/* {route.items?.map((item, index) => (
                                        <Route key={index} path={item.path} element={item.element} exact={item.exact} />
                                    ))} */}
                                </Route>
                            ))}
                        </Route>
      </Routes>
    </HashRouter>
  )
}
