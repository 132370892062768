import React, { useState } from "react";
import CustomButton, {
  BackButton,
  ButtonPosition,
} from "../../components/buttons";
import Facility1 from "../../assets/images/png/facility-details.png";
import { Calendar } from "primereact/calendar";
export default function FacilityAvailability() {
  const [date, setDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  return (
    <div className="container">
      <BackButton />
      <div className="absolute top-0 left-0 z-0">
        <img
          src={Facility1}
          alt="Facility"
          className="w-full"
          style={{ maxHeight: "340px" }}
        />
        <div className="p-3">
          <div className="flex justify-content-between bg-gray border-round-xl p-3 flex-wrap mb-3">
            <p className="m-0">Date</p>
            <i
              className="pi pi-calendar"
              onClick={() => setShowCalendar((prev) => !prev)}
            />
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              inline
              showWeek
              className="w-full mt-5"
              style={{ display: showCalendar ? "block" : "none" }}
            />
          </div>
          <div className="flex justify-content-between bg-gray border-round-xl p-3 flex-wrap mb-3">
            <p className="m-0">Meal Type</p>
            <i
              className="pi pi-chevron-down"
            />
          </div>
          <div className="flex justify-content-between bg-gray border-round-xl p-3 flex-wrap mb-3">
            <p className="m-0">Number of people</p>
            <i
              className="pi pi-chevron-down"
            />
          
          </div>
        </div>
      </div>
      <ButtonPosition>
        <CustomButton label="Reserve a table" />
      </ButtonPosition>
    </div>
  );
}
