import React, { useState } from "react";
import CustomButton, {
  BackButton,
  ButtonPosition,
} from "../../components/buttons";
import { ReactComponent as HouseCleaning } from "../../assets/images/svg/room-cleaning.svg";
import { ReactComponent as WashroomCleaning } from "../../assets/images/svg/washroom-cleaning.svg";
import { ReactComponent as LinenChange } from "../../assets/images/svg/linen-change.svg";
import { ReactComponent as ReplenishAmenities } from "../../assets/images/svg/replenish-amenities.svg";
import { ServiceCard } from "../../components/cards";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();
  const [requested, setRequested] = useState([]);
  const [activeService, setActiveService] = useState(0);
  const serviceList = [
    {
      id: 1,
      name: "House Keeping",
      services: [
        {
          id: 1,
          name: "Room Cleaning",
          icon: <HouseCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 2,
          name: "Washroom Cleaning",
          icon: <WashroomCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 3,
          name: "Linen Change",
          icon: <LinenChange />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 4,
          name: "Replenish Amenities",
          icon: <ReplenishAmenities />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
      ],
    },
    {
      id: 2,
      name: "Maintenance",
      services: [
        {
          id: 1,
          name: "Room Cleaning",
          icon: <HouseCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 2,
          name: "Washroom Cleaning",
          icon: <WashroomCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 3,
          name: "Linen Change",
          icon: <LinenChange />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 4,
          name: "Replenish Amenities",
          icon: <ReplenishAmenities />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
      ],
    },
    {
      id: 3,
      name: "Laundry",
      services: [
        {
          id: 1,
          name: "Room Cleaning",
          icon: <HouseCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 2,
          name: "Washroom Cleaning",
          icon: <WashroomCleaning />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 3,
          name: "Linen Change",
          icon: <LinenChange />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
        {
          id: 4,
          name: "Replenish Amenities",
          icon: <ReplenishAmenities />,
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
        },
      ],
    },
  ];

  const handleServiceRequest = (request, serviceId) => {
    console.log("request, serviceId", request, serviceId);
    if (requested.length)
      setRequested((prev) =>
        prev?.map((service) =>
          service.id === serviceId
            ? { ...service, requests: [...service?.requests, request] }
            : { id: serviceId, requests: [request] }
        )
      );
    else {
      setRequested([{ id: serviceId, requests: [request] }]);
    }
  };

  console.log(
    "requested",
    requested.find((item) => item.requests.find((request) => request.id === 1))
  );

  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Service Request</h1>
      <div className="mb-8">
        {serviceList?.map((option, index) => (
          <div
            key={index}
            className={`border-round-2xl bg-gray mb-3 surs ${
              activeService === index && "active-service"
            }`}
          >
            <div
              className="flex justify-content-between align-items-center cursor-pointer p-3 pl-0"
              onClick={() =>
                setActiveService((prev) => (prev === index ? null : index))
              }
            >
              <p className="m-0 flex align-items-center gap-3">
                <span className="w-1rem h-3rem bg-yellow inline-block" />
                <span className="text-xl">{option?.name}</span>
              </p>
              <i className="pi pi-sort-down-fill text-yellow text-sm" />
            </div>
            <div className="services-container">
              {option.services?.map((service, i) => (
                <ServiceCard
                  data={service}
                  handleBtnAction={() =>
                    handleServiceRequest(service, option?.id)
                  }
                  isRequested={requested.find((item) =>
                    item.requests.find((request) => request.id === service.id)
                  )}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {requested.length !==0  && (
        <ButtonPosition>
          <CustomButton label="Review Request" onClick={()=> navigate('/review-request')}/>
        </ButtonPosition>
      )}
    </div>
  );
}
