import React, { useState } from "react";
import CustomButton, { BackButton, ButtonPosition } from "../../components/buttons";
import { PendingRequestCard } from "../../components/cards";
import { useNavigate } from "react-router-dom";

export default function PendingRequests() {
    const navigate = useNavigate();
    const [pendingRequests, setPendingRequests] = useState([
        {
            title: "Room Cleaning",
            time: "08:30 PM",
            details: "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent."
        },
        {
            title: "Washroom Cleaning",
            time: "08:30 PM",
            details: ""
        }
    ])
  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Pending Requests</h1>
      {
        pendingRequests?.map((request, index)=> <PendingRequestCard key={index} data={request}/>)
      }
      <ButtonPosition><CustomButton label="Add New Request" onClick={()=> navigate("/services")}/></ButtonPosition>
    </div>
  );
}
