import React, { useState } from "react";
import { BackButton } from "../../components/buttons";
import { FoodCard, OptionCard } from "../../components/cards";
import FoodImg from "../../assets/images/png/food-1.png";
import SpecialOffer from "../../assets/images/png/food-offer-1.png";
import { useNavigate } from "react-router-dom";

export default function FoodOrder() {
  const [selectedFood, setSelectedFood] = useState("Recomended");
  const navigate = useNavigate();
  const foodData = [
    {
      name: "Cheesy Chicken Burger",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg,
      category: ["Recomended", "Italian"],
    },
    {
      name: "Chicken lollipop",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg,
      category: ["Recomended", "Italian"],
    },
    {
      name: "Chicken Pizza",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg,
      category: ["Recomended", "Asian"],
    },
    {
      name: "Rice'n Paneer",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg,
      category: ["Recomended", "Asian"],
    },
  ];

  const foodOptions = [
    { label: "Recomended" },
    { label: "Asian" },
    { label: "Mediterranean" },
    { label: "Italian" },
  ];

  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2 mb-0">Food Order</h1>
      <p className="mt-1 text-secondary text-sm">Room 208</p>
      <div className="border-1 flex align-items-center border-round-3xl">
        <i className="pi pi-search m-3" />
        <input
          className="m-0 bg-transparent brder-0 outline-none"
          placeholder="Search for favourite food"
        />
      </div>
      <p className="text-xl">Special Offer</p>
      <div className="flex gap-3 mt-3 overflow-auto">
        <img src={SpecialOffer} alt="" onClick={()=> navigate("/special-offer")}/>
        <img src={SpecialOffer} alt="" onClick={()=> navigate("/special-offer")} />
        <img src={SpecialOffer} alt="" onClick={()=> navigate("/special-offer")} />
      </div>
      <p className="text-xl">Categories</p>
      <OptionCard
        data={foodOptions}
        selected={selectedFood}
        setSelected={setSelectedFood}
        extraClass="mb-4"
      />

      <FoodCard data={foodData} cartItems={[]} />
    </div>
  );
}
