import React from "react";

import { Ripple } from 'primereact/ripple';
        
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";

        
export default function CustomButton({
  type,
  onClick,
  label,
  col,
  extraClass,
  loading,
  disable,
  secondary,
  ...props
}) {
  return (
      <Button
        type={type}
        label={label}
        onClick={onClick}
        loading={loading}
        disabled={disable}
        className={`main-button ${secondary && "btn-secondary"} ${extraClass}`}
        style={{
          background: secondary ? "var(--surface-600)" : "",
          border: secondary ? "none" : "",
        }}
        {...props}
      >
        {" "}
        <Ripple />
      </Button>
  );
}

export function ButtonPosition({children, extraClass=""}){
  return <div className="button-position fixed bottom-0 w-full left-0 pb-6"><div className={`btn-outer flex justify-content-center  ${extraClass}`}>{children}</div></div>
}

export function BackButton({onClick}){
  const navigate = useNavigate();
  return <i className="pi pi-arrow-left text-xl cursor-pointer z-1 relative" onClick={() => onClick ? onClick() :navigate(-1)} />
}

export function ToggleButton({checked, onChange= ()=> null, IconChecked, IconUnchecked, largeCircle, extraClass=""}){
  return <label class="switch">
  <input type="checkbox" checked={checked} onChange={(e)=> onChange({value: e?.target?.checked})}/>
  <span class={`slider ${largeCircle ? "circle-large" : ""} ${extraClass}`}>
   {IconChecked &&  <div className="icon-container"><IconChecked class="icon icon-checked"/></div>}
    {IconUnchecked && <div className="icon-container"><IconUnchecked class="icon icon-unchecked"/></div>}
  </span>
</label>
}

export const CountButton = ({handleAdd, handleRemove, count, extraClass=""})=>{ 
    return <div></div>
}