import React from "react";
import { BackButton } from "../../components/buttons";
import SpecialOffer1 from "../../assets/images/png/food-offer-2.png";
import { FoodCard } from "../../components/cards";
import FoodImg from "../../assets/images/png/food-1.png";

export default function SpecialOffer() {

    const foodData = [
        {
          name: "Cheesy Chicken Burger",
          description:
            "A classic favorite, our chicken burger features a juicy, grilled or... read more",
          energy: "200 Kcal",
          price: 250,
          image: FoodImg,
          category: ["Recomended", "Italian"],
        },
        {
          name: "Chicken lollipop",
          description:
            "A classic favorite, our chicken burger features a juicy, grilled or... read more",
          energy: "200 Kcal",
          price: 250,
          image: FoodImg,
          category: ["Recomended", "Italian"],
        },
        {
          name: "Chicken Pizza",
          description:
            "A classic favorite, our chicken burger features a juicy, grilled or... read more",
          energy: "200 Kcal",
          price: 250,
          image: FoodImg,
          category: ["Recomended", "Asian"],
        },
        {
          name: "Rice'n Paneer",
          description:
            "A classic favorite, our chicken burger features a juicy, grilled or... read more",
          energy: "200 Kcal",
          price: 250,
          image: FoodImg,
          category: ["Recomended", "Asian"],
        },
      ];

  return (
    <div className="container">
      <BackButton />
      <div className="absolute top-0 left-0 z-0">
        <img
          src={SpecialOffer1}
          alt="Facility"
          className="w-full"
          style={{ maxHeight: "340px" }}
        />
        <div className="p-3">
            <p className="text-xl">Offer Name</p>
            <FoodCard data={foodData} cartItems={[]} />
        </div>
      

      </div>
    </div>
  );
}
