import React from "react";
import CustomButton, { CountButton, ToggleButton } from "./buttons";
import { useNavigate } from "react-router-dom";

export const LightControlCard = ({
  Icon,
  label,
  id,
  isOn,
  subTitle = "Power Off",
  extraClass = "",
  gradientClass = "",
  onChange,
}) => {
  return (
    <div className="col-6 align-items-stretch flex">
      <div
        className={`light-control-card p-3 pt-0 border-round-3xl border-gradient w-full cursor-pointer bg-gray ${
          isOn && "gradient-" + (id + 1)
        }  ${gradientClass} ${extraClass}`}
      >
        <div className="flex justify-content-center">
          <Icon />
        </div>
        <p className="text-sm">{label}</p>
        <div className="flex justify-content-between">
          <p className="text-xs text-secondar my-1">{subTitle}</p>
          <ToggleButton
            checked={isOn}
            onChange={({ value }) => onChange({ id, value })}
          />
        </div>
      </div>
    </div>
  );
};

export const OptionCard = ({
  data,
  selected,
  setSelected,
  extraClass = "",
}) => {
  return (
    <div className="flex gap-3 align-items-center mb-5 overflow-auto options-card">
      {data?.map((option, index) => (
        <span
          className={`p-2 border-round-2xl flex align-items-center cursor-pointer gap-2 extraClass ${
            option.label === selected ? "selected-option" : "bg-gray"
          }`}
          onClick={() => setSelected(option?.label)}
        >
          {option?.icon && option?.icon}
          {option?.label}
        </span>
      ))}
    </div>
  );
};

export const FoodCard = ({
  data,
  cartItems,
  handleAdd,
  handleRemove,
  onClick,
}) => {

  const navigate = useNavigate();

  return data?.map((food, index) => (
    <div className="flex border-round-2xl border-1 my-3" key={index}>
      <div className="col">
        <p className="text-white m-0"  onClick={()=> navigate("/food-details")}>{food?.name}</p>
        <p className="m-1 text-secondary text-sm">{food?.description}</p>
        <p className="m-1 text-secondary text-sm">{food?.energy}</p>
        <div className="flex justify-content-between">
          <p className="text-yellow">&#8377; {food?.price}</p>
          {cartItems &&
          cartItems?.find((cart) => cart?.id === food?.id)?.length ? (
            <CountButton
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              count={cartItems.find((cart) => cart?.id === food?.id).qtn}
            />
          ) : (
            <CustomButton secondary label="Add" extraClass={"m-0"} />
          )}
        </div>
      </div>
      <div className="col-3 border-round-2xl p-0" style={{ minWidth: "145px" }}>
        <img src={food?.image} alt={food?.name} className="w-full h-full" />
      </div>
    </div>
  ));
};

export const FacilityCard = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div onClick={()=> navigate("/facility-details")} className="flex justify-content-between gap-3 mb-3 border-1 border-gray-300 border-round-xl">
      <div className="p-3">
        <p className="m-0 font-medium">{data?.title}</p>
        <p className="text-xs">{data?.description1}</p>
        <p className="text-xs">{data?.description2}</p>
        {data?.highlight && (
          <p className="text-xs text-yellow">{data?.highlight}</p>
        )}
      </div>
      <div className="col-4 p-0" style={{ minWidth: "170px" }}>
        <img src={data?.img} alt="facility" className="w-full h-full" />
      </div>
    </div>
  );
};

export const ServiceCard = ({ data, isRequested, handleBtnAction }) => {
  console.log("isRequested", isRequested);
  return (
    <div className="flex align-items-center gap-2 p-3 service-card">
      <div
        className="border-1 border-round-xl p-3 flex align-items-center justify-content-center"
        style={{ minWidth: "76px", height: "76px" }}
      >
        {data.icon}
      </div>
      <div>
        <p className="m-0">{data?.name}</p>
        <p className="text-sm text-secondary m-0">{data?.description}</p>
      </div>
      <CustomButton
        secondary={!isRequested}
        label={isRequested ? "Requested" : "Request"}
        onClick={!isRequested ? handleBtnAction : null}
        extraClass={isRequested && "max-w-max min-w-max"}
      />
    </div>
  );
};

export const PendingRequestCard = ({ data }) => {
  return (
    <div className="bg-gray border-round-xl p-3 mb-3">
      <div className="flex justify-content-between align-items-center">
        <p className="m-0 font-medium">{data?.title}</p>
        <p className="text-xs">{data?.time}</p>
      </div>
      <p className="text-xs">{data?.details}</p>
      <div className="flex justify-content-end">
        <CustomButton label="Mark Complete" secondary extraClass="m-0" />
      </div>
    </div>
  );
};
