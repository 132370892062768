import React from "react";
import CustomButton, { BackButton, ButtonPosition } from "../../components/buttons";
import FoodImg from "../../assets/images/png/food-1.png";
import Delivery from "../../assets/images/png/delivery_dining.png";
import HomeIcon from "../../assets/images/png/home-yellow.png";
import Call from "../../assets/images/png/call.png";
import Notes from "../../assets/images/png/notes.png";

export default function Cart() {
  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Cart</h1>
      <p>Room 208</p>
      <div className="border-1 border-round-xl flex justify-content-between">
        <img alt="" src={FoodImg} />
        <div>
          <p>Cheesy Chicken Burger</p>
          <p className="text-sm">Add on - Pepper Julienned</p>
          <p className="text-yellow mt-4">₹ 300</p>
        </div>
        <div>
          <div
            className="p-2 border-1 border-round-lg m-3"
            style={{ borderColor: "#FF4848" }}
          >
            <span
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                display: "block",
                background: "#FF4848",
              }}
            ></span>
          </div>
        </div>
      </div>
      <div className="border-1 flex align-items-center border-round-3xl mt-4">
        <input
          className="m-0 bg-transparent brder-0 outline-none"
          placeholder="Apply Code"
        />
      </div>
      <div className="p-3 bg-gray border-round-xl mt-4">
        <div className="flex gap-4 align-items-center">
          <div className="py-3 w-1rem flex justify-content-center align-items-center">
            <img src={Delivery} alt="" />
          </div>
          <div className="text-gray-300">
            <span className="text-gray-200">Delivery in</span> 20 mins
          </div>
        </div>
        <div className="flex gap-4 align-items-center">
          <div className="py-3 w-1rem flex justify-content-center align-items-center">
            <img src={HomeIcon} alt="" />
          </div>
          <div className="text-gray-300">
            <span className="text-gray-200">Delivery at </span> Room No. 208
          </div>
        </div>
        <div className="flex gap-4 align-items-center">
          <div className="py-3 w-1rem flex justify-content-center align-items-center">
            <img src={Call} alt="" />
          </div>
          <div className="text-gray-300">
            <span className="text-gray-200">James Miller </span> +91 9898989898
          </div>
        </div>
        <div className="flex gap-4 align-items-center">
          <div className="py-3 w-1rem flex justify-content-center align-items-center">
            <img src={Notes} alt="" />
          </div>
          <div className="text-gray-300 flex col justify-content-between align-items-center">
            <div>
              <span className="text-gray-200">Total Bill </span> ₹ 400 <br />
              <span className="text-gray-200">Incl. Taxes and charges </span>
            </div>
            <i className="pi pi-chevron-right text-xs"/>
          </div>
        </div>
      </div>
      <ButtonPosition>
        <CustomButton label="Place Order - ₹ 400 "/>
      </ButtonPosition>
    </div>
  );
}
