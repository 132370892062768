import React, { useState } from "react";
import CustomButton, { BackButton, ButtonPosition } from "../../components/buttons";
import { useNavigate } from "react-router-dom";

export default function ReviewRequest() {
  const [showAddDetails, setShowAddDetails] = useState(false);
  const navigate = useNavigate();
  const [editService, setEditService] = useState({
    category: null,
    service: {},
  });
  const [data, setData] = useState([
    {
      category: "House Keeping",
      services: [
        {
          title: "Room Cleaning",
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
          details: "",
        },
        {
          title: "Washroom Cleaning",
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
          details: "Please come in the morning for cleaning",
        },
      ],
    },
    {
      category: "Maintenance",
      services: [
        {
          title: "Air Conditioner Repair",
          description:
            "Lorem ipsum dolor sit amet consectetur. Nulla at amet vitae dictum ornare in praesent.",
          details: "",
        },
      ],
    },
  ]);

  return (
    <div className="container">
      {!showAddDetails ? (
        <div>
          <BackButton />
          <h1 className="mt-2">Review Request</h1>
          {data?.map((item) => (
            <div className="bg-gray p-3 mb-3 border-round-xl">
              <p className="font-medium text-xl">{item.category}</p>
              {item?.services?.map((service) => (
                <div className="flex gap-4">
                  <div>
                    <p className="mb-1 font-medium">{service?.title}</p>
                    <p className="mt-1">{service.description}</p>
                    {service.details !== "" ? (
                      <p>Details: {service.details}</p>
                    ) : (
                      <CustomButton
                        secondary
                        label="Add Details"
                        onClick={() => {
                          setShowAddDetails(true);
                          setEditService({
                            category: item?.category,
                            service: service,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <i
                      className="pi pi-trash text-yellow mt-3 text-xl cursor-pointer"
                      onClick={() => null}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <BackButton />
          <h1>Add Details</h1>
          <p className="text-xl font-medium">{editService?.category}</p>
          <p>{editService?.service?.title}</p>
          <p>{editService?.service?.description}</p>
          <textarea placeholder="write your message" className="w-full p-3 h-10rem border-round-xl"></textarea>
          <ButtonPosition><CustomButton label="Submit" onClick={()=> navigate("/pending-requests")}/></ButtonPosition>
        </div>
      )}
    </div>
  );
}
