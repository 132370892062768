import React, { useState } from "react";
import Navigation from "../../components/Navigation";
import Facility1 from "../../assets/images/png/facility1.png";
import Facility2 from "../../assets/images/png/facility2.png";
import { ReactComponent as DiningIcon } from "../../assets/images/svg/dining.svg";
import { ReactComponent as EventsIcon } from "../../assets/images/svg/events.svg";
import { ReactComponent as FitnessIcon } from "../../assets/images/svg/fitness.svg";
import { ReactComponent as SpaIcon } from "../../assets/images/svg/spa.svg";
import { FacilityCard, OptionCard } from "../../components/cards";

export default function Facilities() {
  const [selectedFacility, setSelectedFacility] = useState("Dining");
  const facilityOptions = [
    {
      label: "Dining",
      icon: <DiningIcon />,
    },
    {
      label: "Events",
      icon: <EventsIcon />,
    },
    {
      label: "Fitness",
      icon: <FitnessIcon />,
    },
    {
      label: "Spa",
      icon: <SpaIcon />,
    },
  ];

  const [facilities, setFacilities] = useState([
    {
      title: "Latitude",
      description1: "Cuisine - All Day Dining, Multi Cuisine",
      description2:
        "Timings - 24*7 (except on Tuesday it will be closed between 12:00 AM to 06:00 AM)",
      highlight: "Avg price for 2 ₹ 2500",
      category: "Dining",
      img: Facility1,
    },
    {
      title: "Caramel",
      description1: "Cuisine - All Day Dining, Multi Cuisine",
      description2:
        "Timings - 24*7 (except on Tuesday it will be closed between 12:00 AM to 06:00 AM)",
      highlight: "Avg price for 2 ₹ 2500",
      category: "Dining",
      img: Facility2,
    },
    {
      title: "Caramel",
      description1: "Cuisine - All Day Dining, Multi Cuisine",
      description2:
        "Timings - 24*7 (except on Tuesday it will be closed between 12:00 AM to 06:00 AM)",
      highlight: "Avg price for 2 ₹ 2500",
      category: "Dining",
      img: Facility1,
    },
    {
      title: "Tango",
      description1: "Max Capacity - 200",
      description2:
        "Inbuilt technology allowing drop-down screen, high-speed wireless internet communication through the entire...",
      highlight: "Area: 320 Sq. Mt.",
      category: "Events",
      img: Facility2,
    },
    {
      title: "Caramel",
      description1: "Max Capacity - 40",
      description2:
        "Rhythm hall accommodates a maximum of 40 people and has area of 114 sq. m.",
      highlight: "Area: 320 Sq. Mt.",
      category: "Events",
      img: Facility1,
    },
    {
      title: "Fitness Centre",
      description1: "Monday to Friday -6:00 A.M. to 10:00 P.M",
      description2: "Saturday to Sunday -6:00 A.M. to 10:00 P.M",
      category: "Fitness",
      img: Facility2,
    },
  ]);
  return (
    <div className="container">
      <p className="text-secondary font-italic">Welcome,</p>
      <h2 className="my-2 text-3xl">James Miller 👋🏻</h2>
      <p className="text-secondary text-sm">Room 208, smart management</p>
      <OptionCard
        data={facilityOptions}
        selected={selectedFacility}
        setSelected={setSelectedFacility}
      />
      <div className="mb-8">
        {facilities
          ?.filter((facility) => facility.category === selectedFacility)
          ?.map((facility, index) => (
            <FacilityCard data={facility} key={index} />
          ))}
      </div>
      <Navigation />
    </div>
  );
}
